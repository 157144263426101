import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import LandingPage from './pages/LandingPage/LandingPage';
import MachineLearning from './pages/MachineLearning/MachineLearning';
import CloudComputing from './pages/CloudComputing/CloudComputing';
import FullStackDevelopment from './pages/FullStackDevelopment/FullStackDevelopment';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HelmetProvider } from 'react-helmet-async';

// Setup React Query Client
const queryClient = new QueryClient();

// Component to display while loading
const loading = (
  <div className="loading">
    <div className="spinner-grow text-success" role="status"></div>
  </div>
);

// Lazy load PrivacyPolicyComponent
const PrivacyPolicyComponent = React.lazy(() => import('./pages/TermsAndConditions/TermsAndConditions'));

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <Router>
          <Suspense fallback={loading}>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/machine-learning" element={<MachineLearning />} />
              <Route path="/cloud-computing" element={<CloudComputing />} />
              <Route path="/fullstack-development" element={<FullStackDevelopment />} />
              <Route path="/PrivacyPolicy" element={<PrivacyPolicyComponent />} />
              {/* Add a catch-all route for debugging */}
              <Route path="*" element={<div>Page not found</div>} />
            </Routes>
          </Suspense>
        </Router>
      </HelmetProvider>
    </QueryClientProvider>
  );
}

export default App;

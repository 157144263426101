import React, { useEffect, useRef, useState } from "react";
import Select from 'react-select';
import axios from "axios";
import IntlTelInput from "react-intl-tel-input";
import 'react-intl-tel-input/dist/main.css'; // Import CSS
import '../MachineLearning/style.css'
import logo from '../../assets/IIIT Bangalore.png'
import LEARNING from '../../assets/learning.webp'
import tools from '../../assets/tools.webp'
import { Helmet } from "react-helmet-async";


const CloudComputing = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [error, setError] = useState({
        name: "",
        email: "",
        contact: "",
        selectedOption: ""
    })


    const handlePhoneNumberChange = (isValid, value, countryData, number, id) => {
        setContact(value);
    };




    async function checkForDuplicateLead(email, phone) {
        const webhookUrl = 'https://leadsense.bitrix24.in/rest/31/27mcmp94vg9akfjx/crm.lead.list.json';
        // Prepare query parameters
        const queryParams = new URLSearchParams();
        if (email) {
            queryParams.append('filter[EMAIL]', email);
        }
        if (phone) {
            queryParams.append('filter[PHONE]', phone);
        }
        queryParams.append('select[]', 'ID'); // Add ID to the select array
        const response = await fetch(`${webhookUrl}?${queryParams.toString()}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        return result.result.length > 0 ? result.result[0].ID : null;
    }
    async function addLeadToBitrix24(name, email, phone, course, soursevalue) {
        const existingLeadId = await checkForDuplicateLead(email, phone);
        console.log('ooooo', existingLeadId);
        if (existingLeadId) {
            alert('Your email or phone number is already registered in our system. Our team will be in touch with you shortly..');
            return;
        } else {
            const webhookUrl = 'https://leadsense.bitrix24.in/rest/31/27mcmp94vg9akfjx/crm.lead.add.json';
            const data = {
                fields: {
                    TITLE: 'https://iop.liba.edu/',
                    NAME: name,
                    EMAIL: [{ VALUE: email, VALUE_TYPE: 'WORK' }],
                    PHONE: [{ VALUE: phone, VALUE_TYPE: 'WORK' }],
                    SOURCE_ID: soursevalue,
                    UF_CRM_1637687988896: course // Adding source as source description
                }
            };
            try {
                const response = await fetch(webhookUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json();
                if (result.error) {
                    alert('Error: ' + result.error_description);
                } else {

                }
            } catch (error) {
                console.error('Fetch error:', error);
                alert('Error: ' + error);
            }
        }
    }

    const openCall = () => {
        const phoneNumber = '919597725388'; // Replace with the phone number you want to call
        const callUrl = `tel:${phoneNumber}`;
        window.open(callUrl);
    };
    const handleSubmit = (event) => {
        event.preventDefault();

        // Reset error state
        let errors = {
            name: "",
            email: "",
            contact: "",
            selectedOption: ""
        };

        // Basic validation
        let isValid = true;
        if (!name) {
            errors.name = "Name is required";
            isValid = false;
        }

        if (!contact) {
            errors.contact = "Contact is required";
            isValid = false;
        } else {
            const contactRegex = /^\d{10}$/;
            if (!contactRegex.test(contact)) {
                errors.contact = "Invalid contact number";
                isValid = false;
            }
        }

        if (!email) {
            errors.email = "Email is required";
            isValid = false;
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                errors.email = "Invalid email format";
                isValid = false;
            }
        }

        if (!selectedOption) {
            errors.selectedOption = "Option is required";
            isValid = false;
        }

        setError(errors);
        if (!isValid) {
            return;
        }

        let data = JSON.stringify({
            contact: contact,
            name: name,
            email: email,
            cou: selectedOption ? selectedOption.value : ''
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://api.123admissions.com/api/v1/maipalform',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                // console.log(JSON.stringify(response.data));

                addLeadToBitrix24(name, email, contact, '2301', 'WEB')
                setSelectedOption(null)
                setName('')
                setContact('')
                setEmail('')

            })
            .catch((error) => {
                // console.log(error);
            });
    };
    const inputRef = useRef(null);
    useEffect(() => {
        if (inputRef.current) {
            window.intlTelInput(inputRef.current, {
                initialCountry: 'in',
                separateDialCode: false, // Set to false to hide the flag
            });
        }
    }, []);
    const options = [
        { value: '1', label: 'Executive PG Programme in Machine Learning & Artificial Intelligence' },
        { value: '2', label: 'Post Graduate Certification in Executive ai-powered Full Stack Development' },
        { value: '3', label: 'Executive Post-Graduate Certificate In Cloud Computing And Devops' },

    ];
    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        console.log(`Option selected:`, selectedOption);
    };
    return (<>
        <Helmet>
            <title>Executive Post-Graduate Certificate in Cloud Computing and DevOps - IIIT Bangalore</title>
            <meta
                name="description"
                content="Enroll in the Executive Post-Graduate Certificate in Cloud Computing and DevOps at IIIT Bangalore. Master cloud platforms, automation, continuous integration, and DevOps best practices."
            />
            <meta
                name="keywords"
                content="IIIT Bangalore, Cloud Computing, DevOps, Post Graduate Certificate, Automation, Continuous Integration, Cloud Platforms"
            />
            <meta property="og:title" content="Executive Post-Graduate Certificate in Cloud Computing and DevOps - IIIT Bangalore" />
            <meta
                property="og:description"
                content="Advance your career with the Executive Post-Graduate Certificate in Cloud Computing and DevOps from IIIT Bangalore. Learn cloud platforms, automation, and DevOps with hands-on training."
            />
            <meta property="og:url" content="https://yourwebsite.com/iiit-bangalore/cloud-devops" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="https://yourwebsite.com/images/cloud-devops-banner.jpg" />
            <meta property="og:site_name" content="IIIT Bangalore Online Programmes" />
        </Helmet>
        <div className="landingpage">
            <div className="header">
                <div className="logo">
                    <img src={logo}></img>
                </div>

                <div className="row">
                    <div className="col-lg-12"></div>
                </div>
            </div>
            <div className="applynow">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text">
                            Get ready! The next batch kicks off in 15 days. Reserve your place today!
                            <b style={{ color: "red", marginLeft: "10px" }}>APPLY NOW</b>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contents container">

                <div className="row">
                    <div className="col-lg-12">  <h1 style={{ marginTop: "5%" }}>Executive Post-Graduate Certificate In Cloud Computing And Devops</h1></div>
                    <div className="col-lg-6 mt-3 order2">
                        <h4 className="h2tag" style={{ textAlign: "start", margin: "0px", padding: "0px" }}>About the Programme</h4>
                        <p>The Executive PG Programme in Machine Learning & Artificial Intelligence is designed for working professionals looking to advance their expertise in one of the most transformative technologies of our time. This comprehensive course provides in-depth knowledge and practical skills in machine learning algorithms, AI technologies, and advanced data analytics. Through hands-on projects and industry-relevant case studies, learners will gain a deep understanding of how to develop AI-driven solutions and implement machine learning models across diverse domains.</p>
                        <div className="row">
                            <div className="col-lg-12">
                                <label className="mb-3">Act fast! 691 people have already applied this month – limited seats available!</label>
                            </div>
                            <div className="col-lg-4 col-6 mt-3">

                                <label className="h2tag mb-2" > For enquiries call:</label><br></br>
                                <button className="btn btn-primary" onClick={()=>openCall()}>+91 9597725388</button>

                            </div>
                            <div className="col-lg-4 col-6 mt-3">
                                <label className="h2tag mb-2" >Start Date:</label><br></br>
                                <label>30 september</label>
                            </div>
                            <div className="col-lg-4 mt-3">
                                <label className="h2tag mb-2" >Duration</label> <br></br>
                                <label>13 Months</label>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-6 mt-3">
                        <div className="form">
                            <div className="row">
                                <div className="col-12">
                                    Enter your details and our experts will get in touch with you shortly!
                                </div>
                                <div className="col-12 mt-2" style={{ textAlign: "start" }}>
                                    <input
                                        className="form-control"
                                        placeholder="Enter your name*"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                    <small style={{ color: "red" }}>{error?.name}</small>
                                </div>
                                <div className="col-12 mt-2" style={{ textAlign: "start" }}>
                                    <input
                                        className="form-control"
                                        placeholder="yourname@email.com*"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <small style={{ color: "red" }}>{error?.email}</small>
                                </div>
                                <div className="col-12 mt-2" style={{ textAlign: "start", color: "black" }}>
                                    <IntlTelInput
                                        inputProps={{ id: '91', name: 'india', ref: inputRef }}
                                        onPhoneNumberChange={handlePhoneNumberChange}
                                        defaultCountry="in"
                                        required
                                    />
                                    <small style={{ color: "red" }}>{error?.contact}</small>
                                </div>
                                <div className="col-12 mt-2" style={{ color: "black", textAlign: "start" }}>
                                    <Select
                                        value={selectedOption}
                                        onChange={handleChange}
                                        options={options}
                                        required
                                    />
                                    <small style={{ color: "red" }}>{error?.selectedOption}</small>
                                </div>
                                <div className="col-12 mt-2">
                                    <div style={{ textAlign: "start", fontSize: "10px" }}>
                                        <input style={{ marginRight: "10px" }} type="checkbox" required />
                                        I authorize International Institute of Information Technology Bangalore and its associates to contact me with updates & notifications via email, SMS, WhatsApp, and voice call. This consent will override any registration for DNC / NDNC.
                                    </div>
                                </div>
                                <div className="col-12 mt-5">
                                    <button className="submit btn btn-primary w-100" type="submit" onClick={handleSubmit}>APPLY NOW</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <hr></hr>
            <div className="container">
                <div className="row">

                    <div className="col-lg-6">
                        <h4 className="h2tag">LEARNING PATH</h4>
                        <ul className="mt-5">
                            <li><strong>Data Science Toolkit Preparatory Content:</strong> Statistics and Git Essentials (5 weeks)</li>
                            <li><strong>Machine Learning I:</strong> (7 weeks)</li>
                            <li><strong>Machine Learning II:</strong> (7 weeks)</li>
                            <li><strong>Deep Learning:</strong> (8 weeks)</li>
                            <li><strong>Natural Language Processing:</strong> (7 weeks)</li>
                            <li><strong>Electives:</strong> (DL with MLOps, NLP with MLOps & AI Strategy) (10 weeks)</li>
                            <li><strong>Capstone:</strong> (4 weeks)</li>
                        </ul>
                        <div className="row">
                            <div className="col-lg-12 mt-5 mb-5">
                                <h2 className="h2tag">PROGRAM HIGHLIGHTS</h2>
                                <h5>Executive PG Programme from
                                </h5>
                            </div>
                            <div className="col-lg-12">
                                <div className="programs">
                                    <h4>IIITB and Alumni Status</h4>
                                    <p>Get certified by IIITB and gain alumni status on successful
                                        completion of the program.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="programs">
                                    <h4>For the Industry, by the Industry</h4>
                                    <p>Learn and apply concepts on industry projects and work on a
                                        Capstone Project along with personalised industry mentorship.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="programs">
                                    <h4>Blended Learning
                                    </h4>
                                    <p>Learn with the ease and flexibility of recorded sessions as well
                                        as live sessions, designed to ensure a wholesome learning
                                        experience
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="programs">
                                    <h4>Cutting-Edge Curriculum
                                    </h4>
                                    <p>Master advanced ML and AI concepts developed by industry
                                        experts and faculty.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <h4 className="h2tag">PROGRAMMING TOOLS,
                            LANGUAGES AND LIBRARIES</h4>
                        <img src={tools} style={{ width: "100%", marginTop: "40px" }}></img>
                    </div>
                    <div className="col-lg-12">

                        <h4 className="h2tag mt-5 mb-5">PROGRAM CURRICULUM</h4>

                        <h5 className="mb-5 mt-3">STATISTICS AND EXPLORATORY DATA ANALYTICS (5 WEEKS)</h5>
                        <ul>
                            <li><strong>EXPLORATORY DATA ANALYSIS</strong>: Learn how to find and analyse the patterns in the data to draw actionable insights.</li>
                            <li><strong>CLOUD ESSENTIALS: INTRO TO GIT & GITHUB</strong>: Learn version control, collaborating, portfolio making using Git. Understand the process of creating a repository. Learn the process of creating a GitHub portfolio using GitHub Pages with Jekyll.</li>
                            <li><strong>INFERENTIAL STATISTICS</strong>: Build a strong statistical foundation and learn how to ‘infer’ insights from a huge population using a small sample.</li>
                        </ul>

                        <h5 className="mb-5 mt-3">PRE-PROGRAM PREPARATORY CONTENT (3 WEEKS)</h5>
                        <ul>
                            <li><strong>INTRODUCTION TO PYTHON</strong>: Build a foundation for the most in-demand programming language of the 21st century.</li>
                            <li><strong>PYTHON FOR DATA SCIENCE</strong>: Learn how to manipulate datasets in Python using Pandas, the most powerful library for data preparation and analysis.</li>
                            <li><strong>DATA VISUALISATION IN PYTHON</strong>: Learn to plot and interpret various graphs in Python to make data analysis and drawing insights easier.</li>
                            <li><strong>DATA ANALYSIS USING SQL (OPTIONAL)</strong>: Learn the fundamentals of database and extract information from RDBMS using SQL.</li>
                            <li><strong>ADVANCED SQL AND BEST PRACTICES (OPTIONAL)</strong>: Apply advanced SQL concepts like windowing and procedures to derive insights from data and answer business questions.</li>
                            <li><strong>DATA ANALYSIS IN EXCEL</strong>: Taught by S. Anand, this module takes you from a beginner to an almost professional Excel user.</li>
                            <li><strong>ANALYTICS PROBLEM SOLVING</strong>: Covers concepts of the CRISP-DM framework for business problem-solving.</li>
                            <li><strong>MATH FOR MACHINE LEARNING</strong>: Learn the prerequisite mathematical tools and techniques for ML - Linear Algebra and Multi-variable Calculus.</li>
                            <li><strong>HYPOTHESIS TESTING</strong>: Understand how to formulate and validate hypotheses for a population to solve business problems.</li>
                            <li><strong>LENDING CLUB CASE STUDY</strong>: Determine which customers are at risk of default and avoid providing loans to similar people in the future.</li>
                        </ul>

                        <h5 className="mb-5 mt-3">MACHINE LEARNING  (7 WEEKS)</h5>

                        <ul>
                            <li><strong>LINEAR REGRESSION</strong>: Learn to predict one variable using several others.</li>
                            <li><strong>ADVANCED REGRESSION</strong>: Understand generalised regression and feature selection techniques, including regularisation to counter overfitting.</li>
                            <li><strong>LOGISTIC REGRESSION</strong>: Learn binary classification to determine customer churn.</li>
                            <li><strong>SUPPORT VECTOR MACHINE (OPTIONAL)</strong>: Learn to find a maximal marginal classifier using SVM.</li>
                            <li><strong>NAIVE BAYES</strong>: Build an SMS Spam Ham Classifier using Naive Bayes.</li>
                            <li><strong>TREE MODELS & RANDOM FORESTS</strong>: Replicate decision-making using decision trees and ensemble algorithms.</li>
                            <li><strong>MODEL SELECTION</strong>: Learn model selection methods, regularisation, and cross-validation.</li>
                            <li><strong>BOOSTING</strong>: Learn Adaboost, GBM, and XGBoost to boost weak learners.</li>
                            <li><strong>UNSUPERVISED LEARNING: CLUSTERING</strong>: Group elements into clusters without pre-defined labels using K-means clustering and more.</li>
                            <li><strong>UNSUPERVISED LEARNING: PRINCIPAL COMPONENT ANALYSIS</strong>: Learn dimensionality reduction and PCA applications.</li>
                        </ul>

                        <h5 className="mb-5 mt-3">DEEP LEARNING (8 WEEKS)</h5>
                        <ul>
                            <li><strong>INTRODUCTION TO NEURAL NETWORKS</strong>: Learn the foundations of Artificial Neural Networks (ANNs).</li>
                            <li><strong>CONVOLUTIONAL NEURAL NETWORKS (CNN)</strong>: Apply CNN to tasks like detecting anomalies in X-ray scans and vehicle detection.</li>
                            <li><strong>RECURRENT NEURAL NETWORKS (RNN)</strong>: Understand RNN’s role in machine translation, sentiment analysis, and more.</li>
                            <li><strong>NEURAL NETWORKS PROJECT: GESTURE RECOGNITION</strong>: Build a Smart TV system controlled by hand gestures.</li>
                        </ul>

                        <h5 className="mb-5 mt-3">NATURAL LANGUAGE PROCESSING (7 WEEKS)</h5>
                        <ul>
                            <li><strong>LEXICAL PROCESSING</strong>: Learn how to build a spell checker & spam detector using bag-of-words, TF-IDF, etc.</li>
                            <li><strong>SYNTACTICAL PROCESSING</strong>: Analyse the syntax of sentences using POS tagging and Dependency parsing.</li>
                            <li><strong>SEMANTIC PROCESSING</strong>: Use word-embeddings and topic modeling to extract opinions on socially relevant issues.</li>
                            <li><strong>CASE STUDY: CLASSIFYING CUSTOMER COMPLAINT TICKETS</strong>: Build a solution to identify complaint types raised by bank customers.</li>
                        </ul>

                        <h5 className="mb-5 mt-3">ELECTIVE 1: MLOPS (15 WEEKS)</h5>
                        <ul>
                            <li><strong>INTRODUCTION TO MLOPS</strong>: Overview of MLOps, principles, and practices for integrating ML into the software development lifecycle.</li>
                            <li><strong>EXPERIMENTING WITH DATA AND MODEL USING MLFLOW</strong>: Hands-on experience managing the ML lifecycle using MLflow.</li>
                            <li><strong>AUTOMATING PIPELINES WITH AIRFLOW</strong>: Learn to create and schedule workflows using Airflow.</li>
                            <li><strong>MLOPS PROJECT</strong>: Develop an end-to-end MLOps solution.</li>
                        </ul>

                        <h5 className="mb-5 mt-3">ELECTIVE 2: GENERATIVE AI (15 WEEKS)</h5>
                        <ul>
                            <li><strong>INTRODUCTION TO GENERATIVE AI</strong>: Dive into generative AI and LLMs like GPT, BERT, etc.</li>
                            <li><strong>PRODUCT DEVELOPMENT & INTEGRATING SPEECH USING WHISPER API</strong>: Learn product development fundamentals and deploy GPT-enabled web apps.</li>
                            <li><strong>APPLICATIONS OF LLM IN CODE GENERATION</strong>: Write prompts to generate accurate codes for tasks and build custom LLM-based applications.</li>
                        </ul>

                        <h5 className="mb-5 mt-3">CAPSTONE (4 WEEKS)</h5>
                        <ul>
                            <li><strong>NEWS RECOMMENDER SYSTEM</strong>: Build a model that recommends news stories using natural language processing and recommender systems.</li>
                            <li><strong>CREDIT CARD FRAUD DETECTION</strong>: Develop a machine learning model for detecting fraudulent transactions.</li>
                            <li><strong>EYE FOR BLIND (IMAGE CAPTIONING)</strong>: Build a model that describes the content of an image in speech for the visually impaired.</li>
                        </ul>

                        <h5 className="mb-5 mt-3">REINFORCEMENT LEARNING (OPTIONAL)</h5>
                        <ul>
                            <li><strong>CLASSICAL REINFORCEMENT LEARNING</strong>: Learn algorithms like dynamic programming, Monte Carlo methods, Q Learning.</li>
                            <li><strong>DEEP REINFORCEMENT LEARNING</strong>: Learn deep Q-learning and Policy Gradient Methods.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="footer-content">
                <div className='row'>
                    <div className='col-lg-6 order2' >
                        <img src={logo} style={{ background: "white" }}></img>
                        <a style={{ marginLeft: "40px" }} href="/PrivacyPolicy" className="footer-link">Privacy Policy</a>
                    </div>
                    <div className='col-lg-6'>
                        <a style={{ color: "white", textDecoration: "none" }} href="#" className="footer-link">Machine Learning & Artificial Intelligence</a><br></br>
                        <a style={{ color: "white", textDecoration: "none" }} href="#" className="footer-link">Ai-powered Full Stack Development</a><br></br>
                        <a style={{ color: "white", textDecoration: "none" }} href="#" className="footer-link">Cloud Computing And Devops</a><br></br>
                    </div>
                </div>


            </div>
        </div>
    </>)
}
export default CloudComputing;
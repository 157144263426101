import React, { useEffect, useRef, useState } from "react";
import './LandingPage.css';
import logo from '../../assets/IIIT Bangalore.png'
import col from '../../assets/col-img.webp'
import Select from 'react-select';
import axios from "axios";
import IntlTelInput from "react-intl-tel-input";
import 'react-intl-tel-input/dist/main.css'; // Import CSS
import AIlanding from '../../assets/ai.webp';
import Ai2landing from '../../assets/customer-relationship-management-concept (1) 1.webp';
import Ai3landing from '../../assets/saas-concept-collage 1.webp'
import tools from '../../assets/tools.webp'
import { Helmet } from "react-helmet-async";
import media1 from '../../assets/social media/Group 269.svg'
import media2 from '../../assets/social media/Vector-1.svg'
import media3 from '../../assets/social media/Vector-2.svg'
import media4 from '../../assets/social media/Vector.svg'

const LandingPage = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [error, setError] = useState({
        name: "",
        email: "",
        contact: "",
        selectedOption: ""
    })
    const courseSectionRef = useRef(null);
    const aboutSectionRef = useRef(null);
    const contactSectionRef = useRef(null);

    const scrollToCourseSection = () => {
        courseSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const scrollToaboutSection = () => {
        aboutSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };
    const scrollTocontactSection = () => {
        contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handlePhoneNumberChange = (isValid, value, countryData, number, id) => {
        setContact(value);
    };


    async function checkForDuplicateLead(email, phone) {
        const webhookUrl = 'https://leadsense.bitrix24.in/rest/31/27mcmp94vg9akfjx/crm.lead.list.json';
        // Prepare query parameters
        const queryParams = new URLSearchParams();
        if (email) {
            queryParams.append('filter[EMAIL]', email);
        }
        if (phone) {
            queryParams.append('filter[PHONE]', phone);
        }
        queryParams.append('select[]', 'ID'); // Add ID to the select array
        const response = await fetch(`${webhookUrl}?${queryParams.toString()}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        return result.result.length > 0 ? result.result[0].ID : null;
    }
    async function addLeadToBitrix24(name, email, phone, course, soursevalue) {
        const existingLeadId = await checkForDuplicateLead(email, phone);
        console.log('ooooo', existingLeadId);
        if (existingLeadId) {
            alert('Your email or phone number is already registered in our system. Our team will be in touch with you shortly..');
            return;
        } else {
            const webhookUrl = 'https://leadsense.bitrix24.in/rest/31/27mcmp94vg9akfjx/crm.lead.add.json';
            const data = {
                fields: {
                    TITLE: 'https://iop.liba.edu/',
                    NAME: name,
                    EMAIL: [{ VALUE: email, VALUE_TYPE: 'WORK' }],
                    PHONE: [{ VALUE: phone, VALUE_TYPE: 'WORK' }],
                    SOURCE_ID: soursevalue,
                    UF_CRM_1637687988896: course // Adding source as source description
                }
            };
            try {
                const response = await fetch(webhookUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const result = await response.json();
                if (result.error) {
                    alert('Error: ' + result.error_description);
                } else {

                }
            } catch (error) {
                console.error('Fetch error:', error);
                alert('Error: ' + error);
            }
        }
    }


    const handleSubmit = (event) => {
        event.preventDefault();

        // Reset error state
        let errors = {
            name: "",
            email: "",
            contact: "",
            selectedOption: ""
        };

        // Basic validation
        let isValid = true;
        if (!name) {
            errors.name = "Name is required";
            isValid = false;
        }

        if (!contact) {
            errors.contact = "Contact is required";
            isValid = false;
        } else {
            const contactRegex = /^\d{10}$/;
            if (!contactRegex.test(contact)) {
                errors.contact = "Invalid contact number";
                isValid = false;
            }
        }

        if (!email) {
            errors.email = "Email is required";
            isValid = false;
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                errors.email = "Invalid email format";
                isValid = false;
            }
        }

        if (!selectedOption) {
            errors.selectedOption = "Option is required";
            isValid = false;
        }

        setError(errors);
        if (!isValid) {
            return;
        }

        let data = JSON.stringify({
            contact: contact,
            name: name,
            email: email,
            cou: selectedOption ? selectedOption.value : ''
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://api.123admissions.com/api/v1/maipalform',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                // console.log(JSON.stringify(response.data));

                addLeadToBitrix24(name, email, contact, '2301', 'WEB')
                setSelectedOption(null)
                setName('')
                setContact('')
                setEmail('')

            })
            .catch((error) => {
                // console.log(error);
            });
    };
    const inputRef = useRef(null);
    useEffect(() => {
        if (inputRef.current) {
            window.intlTelInput(inputRef.current, {
                initialCountry: 'in',
                separateDialCode: false, // Set to false to hide the flag
            });
        }
    }, []);
    const Navigate = (e) => {
        window.location.href = e
    }
    const openCall = () => {
        const phoneNumber = '919597725388'; // Replace with the phone number you want to call
        const callUrl = `tel:${phoneNumber}`;
        window.open(callUrl);
    };
    const options = [
        { value: '1', label: 'Executive PG Programme in Machine Learning & Artificial Intelligence' },
        { value: '2', label: 'Post Graduate Certification in Executive ai-powered Full Stack Development' },
        { value: '3', label: 'Executive Post-Graduate Certificate In Cloud Computing And Devops' },

    ];
    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        console.log(`Option selected:`, selectedOption);
    };
    const HangleClickinsta = () => {
        window.open('https://www.instagram.com/iiit_bangalore_online/', '_blank')
    }
    const openWhatsApp = () => {
        const phoneNumber = '919597725388'; // Replace with the phone number you want to open
        const whatsappUrl = `https://wa.me/${phoneNumber}`;
        window.open(whatsappUrl, '_blank');
    };
    const handleClick = (e) => {

        window.open(`${e}`, '_blank');
    };
    return (
        <>
            <Helmet>
                <title>IIIT bangalore Online</title>
                <meta
                    name="description"
                    content="Explore online courses from IIIT Bangalore, including Executive PG Programmes in Machine Learning & AI, Full Stack Development, Cloud Computing, and DevOps."
                />
                <meta
                    name="keywords"
                    content="IIIT Bangalore, Online Courses, Executive PG Program, Machine Learning, Artificial Intelligence, Full Stack Development, Cloud Computing, DevOps"
                />
                <meta property="og:title" content="IIIT Bangalore Online Courses" />
                <meta
                    property="og:description"
                    content="Learn cutting-edge skills through IIIT Bangalore's online Executive PG Programs in Machine Learning & AI, Full Stack Development, and Cloud Computing & DevOps."
                />
                <meta property="og:url" content="https://yourwebsite.com/iiit-bangalore" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://yourwebsite.com/images/iiit-bangalore-banner.jpg" />
                <meta property="og:site_name" content="IIIT Bangalore Online Programs" />
            </Helmet>
            <div className="landingpage">
                <div className="header">
                    <div className="logo">
                        <img src={logo} alt="Logo" />
                    </div>

                    <div className="nav-menu">
                        <label className="lable-tag" onClick={scrollToCourseSection}>Course</label>
                        <label className="lable-tag" onClick={scrollToaboutSection}>About us</label>
                        <label className="lable-tag" onClick={scrollTocontactSection}>Contact us</label>
                    </div>
                </div>

                <div className="applynow">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text">
                                Get ready! The next batch kicks off in 15 days. Reserve your place today!
                                <b style={{ color: "red", marginLeft: "10px" }}>APPLY NOW</b>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contents">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="title">
                                <h1>Explore cutting-edge programs in Computer Science, Data Science, Artificial Intelligence, Cybersecurity, Software Engineering, and Information Systems at IIIT Bangalore</h1>
                                {/* <p>Computer Science | Data Science | Artificial Intelligence | Cybersecurity | Software Engineering | Information Systems</p> */}

                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form">
                                <div className="row">
                                    <div className="col-12">
                                        Enter your details and our experts will get in touch with you shortly!
                                    </div>
                                    <div className="col-12 mt-2" style={{ textAlign: "start" }}>
                                        <input
                                            className="form-control"
                                            placeholder="Enter your name*"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                        <small style={{ color: "red" }}>{error?.name}</small>
                                    </div>
                                    <div className="col-12 mt-2" style={{ textAlign: "start" }}>
                                        <input
                                            className="form-control"
                                            placeholder="yourname@email.com*"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                        <small style={{ color: "red" }}>{error?.email}</small>
                                    </div>
                                    <div className="col-12 mt-2" style={{ textAlign: "start", color: "black" }}>
                                        <IntlTelInput
                                            inputProps={{ id: '91', name: 'india', ref: inputRef }}
                                            onPhoneNumberChange={handlePhoneNumberChange}
                                            defaultCountry="in"
                                            required
                                        />
                                        <small style={{ color: "red" }}>{error?.contact}</small>
                                    </div>
                                    <div className="col-12 mt-2" style={{ color: "black", textAlign: "start" }}>
                                        <Select
                                            value={selectedOption}
                                            onChange={handleChange}
                                            options={options}
                                            required
                                        />
                                        <small style={{ color: "red" }}>{error?.selectedOption}</small>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <div style={{ textAlign: "start", fontSize: "10px" }}>
                                            <input style={{ marginRight: "10px" }} type="checkbox" required />
                                            I authorize International Institute of Information Technology Bangalore and its associates to contact me with updates & notifications via email, SMS, WhatsApp, and voice call. This consent will override any registration for DNC / NDNC.
                                        </div>
                                    </div>
                                    <div className="col-12 mt-5" ref={aboutSectionRef}>
                                        <button className="submit btn btn-primary w-100" type="submit" onClick={handleSubmit}>APPLY NOW</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="body-content  mt-5" style={{

                    paddingTop: "40px"
                }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">

                            </div>
                            <div className="col-lg-6 aboutus">
                                <h2 style={{ color: "white" }}>ABOUT US</h2>
                                <div style={{ padding: "30px 0px", color: "white" }}>
                                    <p style={{ color: "white" }}>The International Institute of Information Technology Bangalore, a Deemed University, popularly known as IIITB, was established in 1998 with a vision to contribute to the IT world by focusing on education and research, entrepreneurship and innovation. The Institute is a registered not-for-profit society funded jointly by the Government of Karnataka and the IT industry.</p>

                                    <p style={{ marginTop: "10%", color: "white" }}>Since its inception, IIITB, with its unique model of education, research, and industry interaction, has grown in stature to become an institution of considerable repute in academic as well as corporate circles. The Institute works in partnership with the corporate sector, while retaining the freedom of an academic institution. It is inspired by other renowned institutions, and also strives to emulate an academic culture that is on par with the best international institutions.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img style={{ borderRadius: "20px" }} src={col}></img>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="container" ref={courseSectionRef} >
                    <div className="row">
                        <div className="col-lg-12 mt-5">
                            <h2 className="h2tag" style={{ textAlign: "center" }}>COURSES</h2>
                        </div>
                        <div className="col-lg-12 mt-5 mb-5">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div class="card mt-2" onClick={() => Navigate('/machine-learning')}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <img src={Ai2landing}></img>
                                                </div>
                                                <div className="col-lg-12">
                                                    <h5>Executive PG
                                                        Programme in Machine Learning & Artificial Intelligence
                                                    </h5>
                                                    <div className="row mt-3">
                                                        <div className="col-lg-6">
                                                            Fee : INR 3,35,000
                                                        </div>
                                                        <div className="col-lg-6">
                                                            Duration : 13 Months
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div class="card mt-2" onClick={() => Navigate('/fullstack-development')}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <img src={AIlanding}></img>
                                                </div>
                                                <div className="col-lg-12">
                                                    <h5>
                                                        Post Graduate Certification in Executive ai-powered Full Stack Development
                                                    </h5>
                                                    <div className="row mt-3">
                                                        <div className="col-lg-6">
                                                            Fee : INR 1,01,000
                                                        </div>
                                                        <div className="col-lg-6">
                                                            Duration : 5 Months
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div class="card mt-2" onClick={() => Navigate('/cloud-computing')}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <img src={Ai3landing}></img>
                                                </div>
                                                <div className="col-lg-12">
                                                    <h5>
                                                        Executive Post-Graduate Certificate In Cloud Computing And Devops
                                                    </h5>
                                                    <div className="row mt-3">
                                                        <div className="col-lg-6">
                                                            Fee : INR 1,80,000
                                                        </div>
                                                        <div className="col-lg-6">
                                                            Duration : 8 Months
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ padding: "10px 50px", background: "rgb(217 217 217)" }}>
                    <div className="row">
                        <div className="col-lg-12 mt-5 mb-5" style={{ textAlign: "center" }}>
                            <h2 className="h2tag">PROGRAM HIGHLIGHTS</h2>
                            <h5>Executive PG Programme from
                            </h5>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="program">
                                <h4>IIITB and Alumni Status</h4>
                                <p>Get certified by IIITB and gain alumni status on successful
                                    completion of the program.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="program">
                                <h4>For the Industry, by the Industry</h4>
                                <p>Learn and apply concepts on industry projects and work on a
                                    Capstone Project along with personalised industry mentorship.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="program">
                                <h4>Blended Learning
                                </h4>
                                <p>Learn with the ease and flexibility of recorded sessions as well
                                    as live sessions, designed to ensure a wholesome learning
                                    experience
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-3">
                            <div className="program">
                                <h4>Cutting-Edge Curriculum
                                </h4>
                                <p>Master advanced ML and AI concepts developed by industry
                                    experts and faculty.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-lg-6">
                            <h4 className="h2tag" style={{ padding: "0px 5%" }}>CAREER SUPPORT</h4>
                            <div className="row" style={{ padding: "0px 10%" }}>
                                <div className="col-12">
                                    <h5 className="mt-3">Interview Preparation</h5>
                                    <ul>
                                        <li>Profile building, communications, etc
                                        </li>
                                        <li>Problem solving approach
                                        </li>
                                        <li>Approaching guesstimates
                                        </li>
                                        <li>Domain specific interview question bank
                                            and much more</li>

                                    </ul>
                                    <h5 className="mt-3">Profile Builder (AI-Powered)</h5>
                                    <ul>
                                        <li>Resume Score: AI-Driven Resume Score

                                        </li>
                                        <li>Real time recommendations to improve
                                        </li>
                                        <li>Match your resume to the JD and check
                                            fitment

                                        </li>
                                        <li>LinkedIn Profile Review</li>

                                    </ul>
                                    <h5 className="mt-3">High Performance</h5>
                                    <ul>
                                        <li>Help you define your value proposition

                                        </li>
                                        <li>Lay out a Career Path and help you
                                            adhere to your timelines and goals

                                        </li>
                                        <li>Help you with interview preparations,
                                            finding jobs in the market, salary
                                        </li>


                                    </ul>
                                    <h5 className="mt-3">Personalised Industry
                                    </h5>
                                    <ul>
                                        <li>Session categories: Career, Technical
                                            and Communications.

                                        </li>
                                        <li>Doubt resolution

                                        </li>
                                        <li>Develop proof of concepts and apply
                                            theoretical concepts in the real world
                                        </li>
                                    </ul>
                                </div>


                            </div>
                        </div>
                        <div className="col-lg-6" style={{ borderRight: "5px soild black" }}>
                            <h4 className="h2tag">PROOGRAMMING TOOLS,
                                LANGUAGES AND LIBRARIES</h4>
                            <img src={tools} style={{ width: "100%" }}></img>
                        </div>
                    </div>
                </div>
                <div className="footer-content" ref={contactSectionRef}>
                    <div className="container">
                        <div className='row'>
                            <div className='col-lg-6' >
                                <a style={{ color: "white", textDecoration: "none" }} href="#" className="footer-link">Machine Learning & Artificial Intelligence</a><br></br>
                                <a style={{ color: "white", textDecoration: "none" }} href="#" className="footer-link">Ai-powered Full Stack Development</a><br></br>
                                <a style={{ color: "white", textDecoration: "none" }} href="#" className="footer-link">Cloud Computing And Devops</a><br></br>
                            </div>
                            <div className='col-lg-6' >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <img src={logo} style={{ background: "white" }}></img>
                                        <a style={{ color: "white", textDecoration: "none" }} href="/PrivacyPolicy" className="footer-link">Privacy Policy</a><br></br>
                                        <a style={{ color: "white", textDecoration: "none" }} onClick={scrollToaboutSection} className="footer-link">About Us</a>
                                    </div>
                                    <div className="col-lg-6">

                                        <div style={{ display: "flex" }}>


                                            <img src={media1} style={{ cursor: "pointer", display: "block", width: "30px", marginRight: "10px" }} onClick={openWhatsApp}></img>
                                            <img src={media2} style={{ cursor: "pointer", display: "block", width: "30px", marginRight: "10px" }} onClick={() => handleClick('#')}></img>
                                            <img src={media3} style={{ cursor: "pointer", display: "block", width: "30px", marginRight: "10px" }} onClick={() => handleClick('https://www.facebook.com/people/IIIT-Bangalore-Online/61565884684097/')}></img>
                                            <img src={media4} style={{ cursor: "pointer", display: "block", width: "30px", marginRight: "10px" }} onClick={HangleClickinsta}></img>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-3" style={{ textAlign: "center", color: "white" }}>
                                © 2015-2024. All rights reserved
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default LandingPage;